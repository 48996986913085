import {
  parseSearchState,
  SearchProvider,
  formatSearchState,
} from '@faststore/sdk'
import { gql } from '@vtex/graphql-utils'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import React, { useMemo, useState, useEffect } from 'react'

import { NpmProductListingPage } from '../local_modules'
import { ITEMS_PER_PAGE } from '../local_modules/constants'
import { applySearchState } from '../local_modules/sdk/search/state'
import { useSession } from '../local_modules/sdk/session'
import { mark } from '../local_modules/sdk/tests/mark'

import redirect from '../../redirect.json'

import type { SearchState } from '@faststore/sdk'
import type {
  CollectionPageQueryQuery,
  ServerCollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'

type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  (ServerCollectionPageQueryQuery & { slug: string }) | null
>

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    serverData,
  } = props

  const selectedFacets = serverData?.collection?.meta.selectedFacets

  const hrefState = useMemo(() => {
    const newState = parseSearchState(
      new URL(href ?? pathname, 'http://localhost')
    )

    // In case we are in an incomplete url
    if (newState.selectedFacets.length === 0) {
      newState.selectedFacets = selectedFacets ?? []
    }

    return formatSearchState(newState).href
  }, [href, pathname, selectedFacets])

  return useMemo(() => parseSearchState(new URL(hrefState)), [hrefState])
}

function Page(props: Props) {
  const {
    data: { site },
    location: { host, search },
    serverData,
  } = props

  const [dynamicShowcaseSkus, setDynamicShowcaseSkus] = useState<string>()

  const { locale } = useSession()
  const searchParams = useSearchParams(props)

  useEffect(() => {
    const params = new URLSearchParams(search)
    const dynamicShowcaseSkusParam = params.get('dynamicShowcaseSkus')

    if (dynamicShowcaseSkusParam) {
      setDynamicShowcaseSkus(dynamicShowcaseSkusParam)
    } else {
      setDynamicShowcaseSkus(undefined)
    }
  }, [searchParams.base])

  // No data was found
  if (serverData === null) {
    return null
  }

  const { collection, slug } = serverData
  const { page } = searchParams
  const title = collection?.seo.title ?? site?.siteMetadata?.title ?? ''
  const pageQuery = page !== 0 ? `?page=${page}` : ''
  const canonical =
    host !== undefined
      ? `https://${host}/${slug}${pageQuery}`
      : `/${slug}${pageQuery}`

  const itemListElements = collection?.breadcrumbList.itemListElement ?? [
    { item: '/', name: title, position: 1 },
  ]


  return (
    <SearchProvider
      onChange={(url) => applySearchState(url, search)}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd itemListElements={itemListElements} />
      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}
      <NpmProductListingPage
        title={title}
        slug={slug}
        itemListElement={itemListElements}
        dynamicShowcaseSkus={dynamicShowcaseSkus}
      />
    </SearchProvider>
  )
}

/**
 * This query is run during SSG
 * */
export const querySSG = graphql`
  query CollectionPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
  }
`

export const querySSR = gql`
  query ServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`
  const { isNotFoundError } = await import('@faststore/api')
  const { execute } = await import('src/server/index')

  const urlRedirect = redirect.find(item => item.from === `/${slug}`)

  const { data, errors = [] } = await execute({
    operationName: querySSR,
    variables: { slug },
  })

  if (urlRedirect) {
    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': ONE_YEAR_CACHE,
        location: `${urlRedirect.to}`,
      },
    }
    throw 'Redirect'
  }

  const notFound = errors.find(isNotFoundError)

  if (notFound) {
    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}`),
    })

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': ONE_YEAR_CACHE,
        location: `/404/?${params.toString()}}`,
      },
    }
  }

  if (errors.length > 0) {
    throw errors[0]
  }

  return {
    status: 200,
    props: { ...data, slug },
    headers: {
      'cache-control': ONE_YEAR_CACHE,
    },
  }
}

Page.displayName = 'Page'
export default mark(Page)
